import React, { ReactNode, createContext, useContext, useEffect, useReducer } from "react";
import { ConfigStateContext } from "../Configurations/context";
import { Filters } from "./model";

const defaultState: Filters = {
	page: {
		value: 1,
		text: "Pagina 1",
	},
	order: {
		value: 2,
		text: "Popularidad",
	},
	bedroomsExactMode: {
		value: false,
		text: "",
	},
	bathroomsExactMode: {
		value: false,
		text: "",
	}
};

const deepEqual = (obj1, obj2) => {
	if (obj1 === obj2) return true;
	if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) return false;

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	if (keys1.length !== keys2.length) return false;

	for (let key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
	}

	return true;
};

export const FiltersStateContext = createContext(defaultState);
export const FiltersDispatchContext = createContext(null);

const reducer = (state: Filters, action): Filters => {
	switch (action.type) {
		case "merge":
			const newStateMerge = { ...state, ...action.payload };
			if (deepEqual(state, newStateMerge)) return state;

			return newStateMerge;
		case "set":
			const newStateSet = { ...action.payload };
			if (deepEqual(state, newStateSet)) return state;

			return newStateSet;
		case "reset":
			return { ...defaultState };
		default:
			throw new Error("no funciona");
	}
	
};

export const FiltersContextProvider = ({
	children,
	initialState = null,
	active,
	callback,
}: {
	children: ReactNode;
	initialState: Filters;
	active: boolean;
	callback?: (state) => void;
}) => {
	if (!active) return <>{children}</>;

	const [state, dispatch] = useReducer(reducer, { ...defaultState, ...initialState });
	const { country_code } = useContext(ConfigStateContext);

	useEffect(() => {
		if (!initialState) return;

		const forceTemporalInCEEE =
			country_code === "ce3"
				? { text: "Alquiler Temporal", value: 4 }
				: state.operation_type_id;

		dispatch({
			type: "merge",
			payload: {
				projects: state.projects,
				page: state.page,
				operation_type_id: forceTemporalInCEEE,
			},
		});
	}, [initialState, country_code]);

	useEffect(() => {
		if (typeof callback === "function") callback(state);
	}, [state]);

	return (
		<FiltersDispatchContext.Provider value={dispatch}>
			<FiltersStateContext.Provider value={state}>{children}</FiltersStateContext.Provider>
		</FiltersDispatchContext.Provider>
	);
};
