import { useContext, useMemo } from "react";
import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { FiltersDispatchContext, FiltersStateContext } from "../../Contexts/Filters/context";
import { Filters, FiltersValues } from "../../Contexts/Filters/model";
import {
	isRent,
	isTemporal,
	propertiesTypeInTemporal,
	showBathrooms,
	showBedrooms,
	showDispositions,
	showFacilities,
	showFloors,
	showPropState,
	showRooms,
} from "../../Utils/Functions";

export const getOnlyValues = (a): FiltersValues => {
	if (a == null || !a) return null;

	const filtersReduced = Object.keys(a).reduce((acc, o) => {
		if (a[o] == null) {
			acc[o] = null;
		} else if (typeof a[o] === 'boolean') {
			acc[o] = a[o];
		} else if (Array.isArray(a[o])) {
			acc[o] = a[o].length > 0 ? a[o].map(j => j.value).flat() : [];
		} else if (a[o].value != null) {
			acc[o] = a[o].value;
		} else if (typeof a[o] == "string" && a[o] != "") {
			acc[o] = a[o];
		}

		return acc;
	}, {});

	return filtersReduced;
};

export const sanitizeMapBound = (filters: FiltersValues): FiltersValues => {
	// if (filters) {
	// 	filters.map_bounds = filters?.map_bounds?.filter(point => point?.latitude && point?.latitude);
	// }
	return filters;
};

const useFilters = () => {
	const filters = useContext(FiltersStateContext);
	const dispatch = useContext(FiltersDispatchContext);
	const { country_code } = useContext(ConfigStateContext);

	const values = useMemo(() => sanitizeMapBound(getOnlyValues(filters)), [filters]);

	const filtersHasNewFilters = (newFilters: Filters) => {
		const newFiltersKeys = Object.keys(newFilters);

		const count = newFiltersKeys.reduce((accumulator, key) => {
			const deepEqual = (obj1, obj2) => {
				if (obj1 === obj2) return true;

				if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
					return false;
				}

				const keys1 = Object.keys(obj1);
				const keys2 = Object.keys(obj2);

				if (keys1.length !== keys2.length) return false;

				for (let key of keys1) {
					if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
						return false;
					}
				}

				return true;
			};

			if (Array.isArray(newFilters[key]) || Array.isArray(filters[key])) {
				if (
					Array.isArray(newFilters[key]) &&
					Array.isArray(filters[key]) &&
					newFilters[key].length === filters[key].length &&
					newFilters[key].every((item, index) => deepEqual(item, filters[key][index]))
				) {
					return accumulator + 1;
				}
			} else if (deepEqual(newFilters[key], filters[key])) {
				return accumulator + 1;
			}
			return accumulator;
		}, 0);


		return newFiltersKeys.length === count;
	};

	const changeFilters = (new_filters: Filters): Promise<Filters> => {
		return new Promise((resolve, reject) => {
			try {
				if ("operation_type_id" in new_filters) {
					if (isTemporal(new_filters.operation_type_id.value)) {
						if (filters.property_type_id) {
							new_filters.property_type_id = filters.property_type_id.filter(e =>
								propertiesTypeInTemporal(e.value)
							);
						}
						new_filters.m2Min = null;
						new_filters.m2Max = null;
						new_filters.m2Type = null;
					}

					if (!isTemporal(new_filters.operation_type_id.value)) {
						new_filters.season = null;
						new_filters.dateFrom = null;
						new_filters.dateTo = null;
						new_filters.guests = null;
					}

					if (!isRent(new_filters.operation_type_id.value)) {
						new_filters.commonExpenses = null;
					}
				}

				if ("property_type_id" in new_filters) {
					let properties = new_filters.property_type_id.map(e => e.value);
					let operation = filters.operation_type_id.value;

					if (!showBedrooms(properties, operation, country_code)) {
						new_filters.bedrooms = null;
					}

					if (!showBathrooms(properties, operation)) {
						new_filters.bathrooms = null;
					}

					if (!showRooms(properties, operation, country_code)) {
						new_filters.rooms = null;
					}

					if (!showPropState(properties, operation)) {
						new_filters.constStatesID = null;
					}

					if (!showFloors(properties)) {
						new_filters.floors = null;
					}

					if (!showDispositions(properties)) {
						new_filters.dispositionID = null;
					}

					if (!showFacilities(properties, operation)) {
						new_filters.facilitiesGroup = null;
					}
				}

				if ("estate_id" in new_filters) {
					if (new_filters.estate_id == null) {
						new_filters.neighborhood_id = [];
					} else {
						new_filters.map_bounds = null
						new_filters.map_polygon = null
					}
				}

				if ("facilitiesGroup" in new_filters && new_filters.facilitiesGroup !== null) {
					new_filters.facilitiesGroup = new_filters.facilitiesGroup.flat();
				}

				// Validación de map_bounds
				if ("map_bounds" in new_filters && new_filters.map_bounds?.value.length) {
					new_filters.map_bounds.value = new_filters.map_bounds?.value.filter(
						item => Object.keys(item).length > 0
					);
				} else if ("map_bounds" in new_filters && new_filters.map_bounds?.value.length === 0) {
					new_filters.map_bounds = null;
				}

				// Validación de map_polygon
				if ("map_polygon" in new_filters && new_filters.map_polygon?.value.length) {
					new_filters.map_polygon.value = new_filters.map_polygon?.value.filter(
						item => Object.keys(item).length > 0
					);
				} else if ("map_polygon" in new_filters && new_filters.map_polygon?.value.length === 0) {
					new_filters.map_polygon = null;
				}

				if(!("page" in new_filters)) {
					new_filters.page = {value: 1, text: ''};
				}
		
				if (filtersHasNewFilters(new_filters)) {
					return resolve(null);
				}

				dispatch({
					type: "merge",
					payload: { ...new_filters },
					country_code: country_code,
				});

				resolve(new_filters);
			} catch (error) {
				reject(error);
			}

		});
	};

	return {
		filters: values,
		filtersTags: filters,
		changeFilters,
	};
};



export { useFilters };

